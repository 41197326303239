<template>
  <b-card>
    <div>
      <b-button-group>
        <b-button
            v-for="(set, name) in sets"
            :key="name"
            @click="function() {changesets({setName: name, val: true})}"
        > {{ set.name }}
        </b-button>
      </b-button-group>
    </div>
    <div class="panel-rule-unit-report d-flex flex-wrap justify-content-between">
      <div class="data-interval d-flex mb-2">
        <div class="date-from mt-1 mr-1 d-flex align-items-center">
          <b-form-datepicker
              id="date-from"
              v-model="dateFrom"
              :label-no-date-selected="'Дата от'"
              @input="inputDateFilterDateFrom"
              :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric'}"
          />
        </div>
        <div class="date-to mt-1 mr-1 d-flex align-items-center">
          <b-form-datepicker
              id="date-to"
              v-model="dateTo"
              :label-no-date-selected="'Дата до'"
              @input="inputDateFilterDateTo"
              :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric'}"
          />
        </div>
        <div class="date-to mt-1 mr-1 d-flex align-items-center">
          <b-form-select
              class="date-filter-reports"
              v-model="currentFilterDate"
              @change="updateByReportId"
              :options="filterDate.map(item=>({text: `#${item.realizationreport_id} от ${item.date_from} до ${item.date_to}`, value: `${item.date_from}to${item.date_to}`}))"
          >
          </b-form-select>
        </div>
        <b-form-checkbox
            class="ml-1"
            style="margin-top: 24px"
            @change="function(val) {filterSurplus = val}"
            :checked="filterSurplus"
        >
          С остатком
        </b-form-checkbox>
      </div>
      <div class="d-flex  mb-2">
        <div class="mr-1 mt-1 d-flex align-items-center">
          <b-form-select
              v-model="filterBrand"
              @change="function(val) { if(val === 'Все') val = ''; filterBrand = val; getByFiltered()}"
              :options="getListBrands"
          >
            <template #first>
              <b-form-select-option :value="null">бренды</b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div class="d-flex mr-1 mt-1 align-items-center">
          <b-form-select
              v-model="filterObject"
              @change="function(val) {if(val === 'Все') val = ''; filterObject = val; getByFiltered()}"
              :options="getListObjects"
          >
            <template #first>
              <b-form-select-option :value="null">категории</b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div class="d-flex mt-1 align-items-center">
          <b-form-select
              v-model="filterArticles"
              @change="function(val) {if(val === 'Все') val = ''; filterArticles = val; getByFiltered()}"
              :options="getListArticles"
              placeholder="Артикул"
          >
            <template #first>
              <b-form-select-option :value="null">артикулы</b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div class="d-flex mt-1 ml-1 align-items-center">
          <b-form-select
              v-model="currentWarehouse.warehousename"
              @change="function(val) {if(val === 'Все') val = ''; currentWarehouse.warehousename = val; getByFiltered()}"
              :options="getStockList"
              placeholder="Артикул"
          >
            <template #first>
              <b-form-select-option :value="null">склады</b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div class="d-flex mt-1 ml-1 align-items-center">
          <b-form-select
              v-model="currentRegionname"
              @change="filteredByRegion"
              :options="Object.keys(getRegionName)"
              placeholder="Регионы"
          >
            <template #first>
              <b-form-select-option :value="null">регионы</b-form-select-option>
            </template>
          </b-form-select>
        </div>
      </div>
    </div>
    <div>
      <b-table id="table_unit_report"
               responsive
               :thead-tr-class="'text-nowrap'"
               :fields="getFieldsByGroup"
               first-number
               show-empty
               last-number
               small
               :thAttr="getStyleTh"
               :details-td-class="'p-0'"
               :items="getUnitReportRows"
               ref="table">
        <template #thead-top="data">
          <b-tr>
            <b-th v-for="(item, i) in getMainHeaders"
                  :key="item.hr+i"
                  style="height: 44px"
                  :class="getClassesMainHeader(item)"
                  variant="primary"
                  :colspan="item.val">
              <div class="d-flex align-items-center" style="width: 100%; height: 100%"
                   :class="item.hasOwnProperty('group') ? 'justify-content-between' : 'justify-content-center'">
                <div v-if="item.hasOwnProperty('group')" style="width: 19px; height: 14px;"></div>
                <div style="text-transform: none; text-align: center">
                  {{ item.hr || item.label }}
                </div>
                <div v-if="item.hasOwnProperty('group')">
                  <feather-icon
                      :icon="item.group ?'MinusSquareIcon' : 'PlusSquareIcon' "
                      class="cursor-pointer"
                      style="margin-left: 5px"
                      @click="function() {setVisibilityFields( {parentField: item.key})}"/>
                </div>
              </div>
            </b-th>
          </b-tr>
        </template>
        <template #head()="data">
          <div class="d-flex"
               :class="['quantity','summa','pay_to_finaccount','promo_summa','summa_val_value','surplus'].indexOf(data.field.key) !== -1 ? 'justify-content-between' : 'justify-content-center'"
               :style="{width: data.field.columnWidth}">
            <div style="width: 14px"
                 v-if="['quantity','summa','pay_to_finaccount','promo_summa','summa_val_value','surplus'].indexOf(data.field.key) !== -1"
            />
            <div style="text-transform: none; max-width: 105px; white-space: normal; font-weight: lighter">{{
                data.label
              }}
            </div>
            <sort-icon
                v-if="['quantity','summa','pay_to_finaccount','promo_summa','summa_val_value','surplus'].indexOf(data.field.key) !== -1"
                @sort="function(val) {getByFiltered({sortDir: val, sortField: data.field.key})}"
            />
          </div>
          <div style="font-weight: normal; margin-top: 5px; text-align: center">
            <money-format
                v-if="unitReportSum[data.column]"
                :cost="(Math.round(unitReportSum[data.column] * 100) / 100)"/>
          </div>
        </template>
        <template #cell()="data">
          <div v-if="data.field.key === 'product'"
               :style="{width: data.field.columnWidth}"
               style="overflow-x: hidden">
            <product-cell-unit
                :productData="data.item"
                @openDetail="function(){
                   tumblerDetail(data.item);
              }"
            />
          </div>
          <div :style="{width: data.field.columnWidth}"
               style="text-align: center"
               v-else>
            <money-format
                :cost="+data.item[data.field.key]"
            />
          </div>
        </template>
        <template #head(sub_sort)="data">
          <div class="d-flex flex-column justify-content-between"
               :style="{width: data.field.columnWidth}">
            <div>
              <div style="padding-left: 4px; font-size: 8px; color: rgb(129 135 147)">
                Кол-во дней
              </div>
              <b-form-input
                  style="width: 100px"
                  size="sm"
                  v-model="periodSubSort"
                  placeholder="14"
                  @blur="getByFiltered"
                  @keyup.enter="getByFiltered">

              </b-form-input>
            </div>
          </div>
        </template>
        <template #head(product)="data">
          <div :style="{width: data.field.columnWidth}"
               style="height: 43px;">
          </div>
        </template>
        <template #cell(expenses_mp_sum)="data">
          <div style="text-transform: none;" :style="{width: data.field.columnWidth}">
            {{
              (
                  +(+data.item.delivery || 0) +
                  +(+data.item.delivery_unit || 0) +
                  +(+data.item.penalty || 0) +
                  +(-data.item.additional_payment || 0) +
                  +(+data.item.storage_cost || 0) +
                  +(+data.item.storage_fee_item || 0) +
                  +(+data.item.cost_acceptance || 0)
              ).toFixed(2).replace('.', ',')
            }}
          </div>
        </template>
        <template #cell(expenses_mp_unit)="data">
          <div style="text-transform: none;" :style="{width: data.field.columnWidth}">
            {{
              ((+(+data.item.delivery || 0) +
                  +(+data.item.delivery_unit || 0) +
                  +(+data.item.penalty || 0) +
                  +(-data.item.additional_payment || 0) +
                  +(+data.item.storage_cost || 0) +
                  +(+data.item.storage_fee_item || 0) +
                  +(+data.item.cost_acceptance || 0)) / (+data.item.quantity || 1)).toFixed(2).replace('.', ',')
            }}
          </div>
        </template>
        <template #cell(expenses_mp_percent)="data">
          <div :style="{width: data.field.columnWidth}">
            {{
              (typeof +data.item.summa === "number" && +data.item.summa !== 0 ? Math.round((
                  +(+data.item.delivery || 0) +
                  +(+data.item.delivery_unit || 0) +
                  +(+data.item.penalty || 0) +
                  +(-data.item.additional_payment || 0) +
                  +(+data.item.storage_cost || 0) +
                  +(+data.item.storage_fee_item || 0) +
                  +(+data.item.cost_acceptance || 0)
              ) / data.item.summa * 100) : 0) + '%'
            }}
          </div>
        </template>
        <template #cell(sales_percent)="data">
          <div :style="{width: data.field.columnWidth}">
            {{ Math.round(data.item.sales_summ / unitReportSum.sales_summ * 100) + '%' }}
          </div>
        </template>
        <template #cell(delivery_unit)="data">
          <div :style="{width: data.field.columnWidth}">
            {{ Math.round((data.item.delivery / (+data.item.quantity || 1))) || 0 }}
          </div>
        </template>
        <template #cell(sales_chart)="data">
          <div class="item_chart-main-container"
               :style="{width: `${(periodLength - 1) * 6 + 10}px`}">
            <div class="d-flex align-items-end item_chart-container_sales item_chart-container"
                 :style="{width: `${(periodLength - 1) * 6}px`}"
            >
              <div class="item_chart item_chart_sales"
                   v-for="(item, name) in realByDays"
                   :key="name + '-' + data.item.nmID"
                   :style="{height: `${((item[data.item.nmID] || 0)/realByDaysMax[data.item.nmID]) * 100}%`}"
                   v-b-popover.hover.top="item[data.item.nmID]"
                   :title="name">
              </div>
            </div>
          </div>
        </template>
        <template #cell(order_chart)="data">
          <div class="item_chart-main-container"
               :style="{width: `${(periodLength - 1) * 6 + 10}px`}">
            <div class="d-flex align-items-end item_chart-container_order  item_chart-container"
                 :style="{width: `${(periodLength - 1) * 6}px`}">
              <div class="item_chart item_chart_order"
                   v-for="(item, name) in ordersByDays"
                   :key="name + '-' + data.item.nmID"
                   :style="{height: `${((item[data.item.nmID] || 0)/ordersByDaysMax[data.item.nmID]) * 100}%`}"
                   v-b-popover.hover.top="item[data.item.nmID]"
                   :title="name">
              </div>
            </div>
          </div>
        </template>
        <template #cell(order_percent)="data">
          <div :style="{width: data.field.columnWidth}">
            {{ Math.round(data.item.order_summ / unitReportSum.order_summ * 100) + '%' }}
          </div>
        </template>
        <template #cell(forecast)="data">
          <div :style="{width: data.field.columnWidth}">
            <b-form-input
                v-if="!data.item.is_dimensional"
                style="width: 100px"
                size="sm"
                placeholder=""
                @keyup.enter="function(val) {addSubSortToOperation({val, ...data.item})}"
                @input="function(val) {addSubSortToOperation({val, ...data.item})}"
                v-model="data.item.sub_sort_value"
            ></b-form-input>
            <div v-else>
              {{
                unitReportDetails.reduce((acc, set) => {
                  if (set[0] && set[0].nmID === data.item.nmID) return set.reduce((acc1, val) => +acc1 + +val.sub_sort_value, 0);
                  return 0
                }, 0)
              }}
            </div>
          </div>
        </template>
        <template #cell(sub_sort)="data">
          <div :style="{width: data.field.columnWidth}">
             <span style="color: #2d87c2; border-bottom: 1px dashed; cursor: pointer"
                   v-if="!data.item.is_dimensional"
                   @click="function() {
                  setSubSort({nmID: data.item.nmID, barcode: data.item.barcode, value: data.item.sub_sort || 0});
                  addSubSortToOperation({val: data.item.sub_sort || 0 , ...data.item});
                }">
               {{ data.item.sub_sort || 0 }}
             </span>
          </div>
        </template>
        <template #cell(promo_total)="data">
          <div :style="{width: data.field.columnWidth}">
            {{ +data.item.promo_summa + +data.item.promo_summa_balance }}
          </div>
        </template>
        <template #cell(promo_unit)="data">
          <div :style="{width: data.field.columnWidth}">
            {{ getPromoUnit(data) }}
          </div>
        </template>
        <template #cell(promo_percent)="data">
          <div :style="{width: data.field.columnWidth}">
            {{
              (typeof +data.item.summa === "number" && +data.item.summa !== 0 && (+data.item.promo_summa + +data.item.promo_summa_balance) !== 0 ? Math.round(+(+data.item.promo_summa + +data.item.promo_summa_balance) / data.item.summa * 100) : 0) + '%'
            }}
          </div>
        </template>
        <template #cell(sales_promo_total)="data">
          <div :style="{width: data.field.columnWidth}">
            {{ +data.item.sales_promo_summa + +data.item.promo_summa_balance }}
          </div>
        </template>
        <template #cell(sales_promo_unit)="data">
          <div :style="{width: data.field.columnWidth}">
            {{ getPromoUnit(data) }}
          </div>
        </template>
        <template #cell(sales_promo_percent)="data">
          <div :style="{width: data.field.columnWidth}">
            {{
              (typeof +data.item.summa === "number" && +data.item.summa !== 0 && (+data.item.promo_summa + +data.item.promo_summa_balance) !== 0 ? Math.round(+(+data.item.promo_summa + +data.item.promo_summa_balance) / data.item.summa * 100) : 0) + '%'
            }}
          </div>
        </template>
        <template #cell(unit_val_value)="data">
          <div :style="{width: data.field.columnWidth}">
            {{
              (+data.item.quantity ? (data.item.summa_val_value / (+data.item.quantity)) : 0).toFixed().replace('.', ',')
            }}
          </div>
        </template>
        <template #cell(perc_val_value)="data">
          <div :style="{width: data.field.columnWidth}">
            {{
              (typeof +data.item.pay_to_finaccount === "number" && +data.item.pay_to_finaccount !== 0 ? Math.round(+data.item.summa_val_value / data.item.pay_to_finaccount * 100) : 0) + '%'
            }}
          </div>
        </template>
        <template #cell(cost_price_unit)="data">
          <div :style="{width: data.field.columnWidth}">
            {{ (+data.item.quantity ? (data.item.cost_price / (+data.item.quantity)) : 0).toFixed().replace('.', ',') }}
          </div>
        </template>
        <template #cell(cost_price_percent)="data">
          <div :style="{width: data.field.columnWidth}">
            {{
              (typeof +data.item.summa === "number" && +data.item.summa !== 0 && data.item.cost_price !== 0 ? Math.round(+data.item.cost_price / data.item.summa * 100) : 0) + '%'
            }}
          </div>
        </template>
        <template #cell(sales_cost_price_unit)="data">
          <div :style="{width: data.field.columnWidth}">
            {{ (+data.item.sales_amount ? (data.item.sales_cost_price / (+data.item.sales_amount)) : 0).toFixed().replace('.', ',') }}
          </div>
        </template>
        <template #cell(sales_cost_price_percent)="data">
          <div :style="{width: data.field.columnWidth}">
            {{
              (typeof +data.item.summa === "number" && +data.item.summa !== 0 && data.item.sales_cost_price !== 0 ? Math.round(+data.item.sales_cost_price / data.item.summa * 100) : 0) + '%'
            }}
          </div>
        </template>
        <template #row-details="data">
          <table-unit-details-size
              :nmID="data.item.nmID"
          />
        </template>
      </b-table>
    </div>
    <purchase-list-popup/>
  </b-card>
</template>


<script>

import {
  BInputGroup,
  BButton,
  BButtonGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormDatepicker,
  BFormSelectOption,
  BTable,
  VBPopover,
  BCard,
  BAvatar,
  BFormCheckbox,
  BFormSelect,
  BTr,
  BTh
} from 'bootstrap-vue'

import SortIcon from "@/components/SortIcon";
import {getToDay} from "@/libs/date";
import PurchaseListPopup from "@/components/procurement/PurchaseListPopup";
import ProductCellUnit from "@/components/ProductCellUnit";
import TableUnitDetailsSize from "@/components/unitReport/TableUnitDetailsSize";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormSelectOption,
    BFormDatepicker,
    BButton,
    BButtonGroup,
    BFormSelect,
    PurchaseListPopup,
    TableUnitDetailsSize,
    BFormCheckbox,
    ProductCellUnit,
    SortIcon,
    BTable,
    BCard,
    BAvatar,
    BFormInput,
    BTr,
    BTh
  },
  directives: {
    'b-popover': VBPopover
  },
  name: "UnitReport",
  data() {
    return {
      thead: null,
      findText: '',
      periodSubSort: 14,
      dateFrom: getToDay(-7),
      dateTo: getToDay(),
      filterBrand: null,
      filterObject: null,
      filterArticles: null,
      currentRegionname: null,
      filterSurplus: true,
      currentWarehouse: {
        countryname: null,
        oblastokrugname: null,
        regionname: null,
        warehousename: null,
      },
      currentFilterDate: ``,
    }
  },
  computed: {
    ...mapState('products', ["brands", "vendorCodes", "objects"]),
    ...mapState('unitReportScheme', ["classCell", "classMainTH", "sets", "warehouses", "stock"]),
    ...mapState('unitReport', ["unitReportDetails", "listFieldsByGroup", "unitReportRows", "unitReportSum", "filterDate", "realByDays", "ordersByDays", "ordersByDaysMax", "realByDaysMax", "periodLength"]),
    ...mapGetters('unitReport', ["getLengthsDetails"]),
    ...mapGetters('unitReportScheme', ["getFieldsByGroup", "getMainHeaders"]),
    getListBrands() {
      return this.brands
    },

    getStockList() {
      return this.stock.map(item => item.warehousename)
    },

    getCountryOrderList() {
      let res = {}
      this.warehouses.forEach(item => res[item.countryname] = 1);
      return Object.keys(res)
    },

    getRegionName() {
      let res = {}
      this.warehouses.forEach(item => res[item.oblastokrugname || item.countryname] = {
        oblastokrugname: item.oblastokrugname,
        countryname: item.countryname
      });
      return res
    },

    getListWarehouses() {
      if (this.currentWarehouse.warehousename) return [this.currentWarehouse.warehousename];
    },

    getCountryOrder() {
      if (this.currentWarehouse.countryname) return [this.currentWarehouse.countryname];
    },

    getRegionOrder() {
      if (this.currentWarehouse.oblastokrugname) return [this.currentWarehouse.oblastokrugname];
    },

    getStyleTh(arg) {
      console.log('arg', arg)
    },

    getListObjects() {
      return this.objects
    },

    getListArticles() {
      return this.vendorCodes
    },

    getUnitReportRows() {
      if (this.filterSurplus) return this.unitReportRows.filter(item => item.surplus > 0);
      return this.unitReportRows;
    }
  },
  methods: {
    ...mapActions('unitReport', ["clearSubSortToOperation", "addSubSortToOperation", "getUnitReport", 'addSubSort', "getUnitReportDetail", "getDatesReports", "addRows", "delRows", "getRealReportByDay", "getOrdersByDay"]),
    ...mapActions('unitReportScheme', ["setVisibilityFields", "getWarehouses", "changesets", "getStock"]),
    ...mapActions('purchase', ["loadListPurchase"]),

    getClassesMainHeader(item) {
      let classes = ['main-header', `main-header-${item.key}`]

      if (item.key === 'product') {
        classes.push('b-table-sticky-column');
      }

      return classes
    },

    getPromoUnit(data) {
      if (data.item.quantity > 0) {
        return ((+data.item.promo_summa + +data.item.promo_summa_balance) / data.item.quantity).toFixed(2).replace('.', ',');
      }
      return 0
    },

    handleWindowScroll() {
      if (window.scrollY > 0) {
        this.thead.style.top = `${window.scrollY - 200}px`;
      } else {
        this.thead.style.position = '';
        this.thead.style.top = '';
        this.thead.style.width = '';
      }
    },

    async filteredByRegion(val) {
      this.currentWarehouse.countryname = this.getRegionName[val]?.countryname
      this.currentWarehouse.oblastokrugname = this.getRegionName[val]?.oblastokrugname
      await this.getByFiltered()
    },

    tumblerDetail(item) {
      item._showDetails = !item._showDetails;
      let exist = this.unitReportDetails.some(detailSet => detailSet.some(size => +size.nmID === +item.nmID));
      if (!exist) {
        this.addRows({
          nmID: item.nmID,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          brand: this.filterBrand,
          object: this.filterObject,
          vendorCode: this.filterArticles,
          sortDir: item._sortDir,
          sortField: item._sortField,
          periodSubSort: this.periodSubSort,
        });
      }
    },

    inputDateFilterDateFrom(value) {
      this.dateFrom = value
      this.getByFiltered()
    },

    inputDateFilterDateTo(value) {
      this.dateTo = value
      this.getByFiltered()
    },

    async updateByReportId(value) {
      let [dateFrom, dateTo] = value.split('to');
      this.dateTo = dateTo;
      this.dateFrom = dateFrom;
      await this.getByFiltered();
    },

    async setSubSort({nmID, barcode, value}) {
      await this.addSubSort({nmID, barcode, value})
    },

    async getByFiltered(params = {sortDir: '', sortField: ''}) {
      await this.getUnitReport({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        brand: this.filterBrand,
        object: this.filterObject,
        vendorCode: this.filterArticles,
        sortDir: params.sortDir,
        sortField: params.sortField,
        periodSubSort: this.periodSubSort,
        warehosesNames: this.getListWarehouses,
        countryName: this.getCountryOrder,
        regionname: this.getRegionOrder,
      });
    },
  },
  watch: {},
  async mounted() {
    this.thead = this.$refs.table.$el.querySelector('#table_unit_report thead');
    await this.getStock();

    await this.getDatesReports();
    await this.getByFiltered();
    this.currentFilterDate = `${this.filterDate[0]?.date_from}to${this.filterDate[0]?.date_to}`;
    await this.getWarehouses({dateFrom: this.dateFrom, dateTo: this.dateTo});
    window.addEventListener('scroll', this.handleWindowScroll);
    // this.loadListPurchase();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleWindowScroll);
  },
}
</script>

<style scoped>
.forecast-container {
  width: 150px;
}

.item_chart {
  width: 5px;
  min-width: 5px;
  margin-right: 1px;
}

.item_chart_sales {
  background-color: #7addbf;
}

.item_chart-container_sales {
  border-bottom: 1px solid #62a38d;
}

.item_chart_order {
  background-color: #8dafe6;
}

.item_chart-container_order {
  border-bottom: 1px solid #5d84b9;
}

.btn-detail-cont {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.item_chart-container {
  margin: 20px 0;
  height: 40px;
  overflow-x: hidden;
}

.item_chart-main-container {
  display: flex;
  align-items: end;
}

.card-contact {
  flex: 1 1 auto;
  padding: 1.5rem;
  background-color: rgba(186, 191, 199, .12);
  margin-bottom: 2rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
}
</style>