<template>
  <div style="background-color: #F8F8FC">
    <b-table
        id="table_detail_size_unit_report"
        class="detail-unit"
        :fields="getFieldsByGroup"
        :items="getDetailsRows"
        :thead-tr-class="'text-nowrap'"
    >
      <template #head()="data">
        <div :style="{width: data.field.columnWidth}"></div>
      </template>
      <template #cell()="data">
        <div :style="{width: data.field.columnWidth}">
          <div v-if="data.field.key === 'product'"
               style="padding-left: 34px; text-align: left">
            <div>
              {{ `${data.item.techSize}(${data.item.wbSize})` }}
            </div>
            <div>
              {{ `${data.item.barcode}` }}
            </div>
          </div>
          <div v-else>
            <money-format :cost="+data.item[data.field.key]"/>
          </div>
        </div>
      </template>
      <template #cell(expenses_mp_sum)="data">
        <div style="text-transform: none;" :style="{width: data.field.columnWidth}">
          {{
            (
                +(+data.item.delivery || 0) +
                +(+data.item.delivery_unit || 0) +
                +(+data.item.penalty || 0) +
                +(-data.item.additional_payment || 0) +
                +(+data.item.storage_cost || 0) +
                +(+data.item.storage_fee_item || 0) +
                +(+data.item.cost_acceptance || 0)
            ).toFixed(2).replace('.', ',')
          }}
        </div>
      </template>
      <template #cell(expenses_mp_unit)="data">
        <div style="text-transform: none;" :style="{width: data.field.columnWidth}">
          {{
            ((
                +(+data.item.delivery || 0) +
                +(+data.item.delivery_unit || 0) +
                +(+data.item.penalty || 0) +
                +(-data.item.additional_payment || 0) +
                +(+data.item.storage_cost || 0) +
                +(+data.item.storage_fee_item || 0) +
                +(+data.item.cost_acceptance || 0)
            ) / (+data.item.quantity || 1)).toFixed(2).replace('.', ',')
          }}
        </div>
      </template>
      <template #cell(expenses_mp_percent)="data">
        <div :style="{width: data.field.columnWidth}">
          {{
            (typeof +data.item.summa === "number" && +data.item.summa !== 0 ? Math.round((
                +(+data.item.delivery || 0) +
                +(+data.item.delivery_unit || 0) +
                +(+data.item.penalty || 0) +
                +(-data.item.additional_payment || 0) +
                +(+data.item.storage_cost || 0) +
                +(+data.item.storage_fee_item || 0) +
                +(+data.item.cost_acceptance || 0)
            ) / data.item.summa * 100) : 0) + '%'
          }}
        </div>
      </template>
      <template #cell(sales_percent)="data">
        <div :style="{width: data.field.columnWidth}"></div>
        {{ Math.round(data.item.sales_summ / unitReportSum.sales_summ * 100) + '%' }}
      </template>
      <template #cell(delivery_unit)="data">
        <div :style="{width: data.field.columnWidth}">
          {{ Math.round((data.item.delivery / (+data.item.quantity || 1))) || 0 }}
        </div>
      </template>
      <template #cell(sales_chart)="data">
        <div class="item_chart-main-container"
             :style="{width: `${(periodLength - 1) * 6 + 10}px`}">
        </div>
      </template>
      <template #cell(order_chart)="data">
        <div class="item_chart-main-container"
             :style="{width: `${(periodLength - 1) * 6 + 10}px`}">
        </div>
      </template>
      <template #cell(order_percent)="data">
        <div :style="{width: data.field.columnWidth}">
          {{ Math.round(data.item.order_summ / unitReportSum.order_summ * 100) + '%' }}
        </div>
      </template>
      <template #cell(forecast)="data">
        <div :style="{width: data.field.columnWidth}">
          <b-form-input
              style="width: 100px"
              size="sm"
              placeholder=""
              @keyup.enter="function(val) {addSubSortToOperation({val, ...data.item})}"
              @input="function(val) {addSubSortToOperation({val, ...data.item})}"
              v-model="data.item.sub_sort_value"
          ></b-form-input>
        </div>
      </template>
      <template #cell(sub_sort)="data">
        <div :style="{width: data.field.columnWidth}">
          <span style="color: #2d87c2; border-bottom: 1px dashed; cursor: pointer"
                @click="function() {
                  setSubSort({nmID: data.item.nmID, barcode: data.item.barcode, value: data.item.sub_sort || 0});
                  addSubSortToOperation({val: data.item.sub_sort, ...data.item});
                }">
               {{ data.item.sub_sort || 0 }}
          </span>
        </div>
      </template>
      <template #cell(promo_main)="data">
        -
      </template>
      <template #cell(promo_unit)="data">
        -
      </template>
      <template #cell(promo_percent)="data">
        -
      </template>
      <template #cell(promo_summa)="data">
        -
      </template>
      <template #cell(promo_summa_balance)="data">
        -
      </template>
      <template #cell(ddr)="data">
        -
      </template>
      <template #cell(unit_val_value)="data">
        <div :style="{width: data.field.columnWidth}">
          {{
            (+data.item.quantity ? (data.item.summa_val_value / (+data.item.quantity)) : 0).toFixed().replace('.', ',')
          }}
        </div>
      </template>
      <template #cell(perc_val_value)="data">
        <div :style="{width: data.field.columnWidth}">
          {{
            (typeof +data.item.pay_to_finaccount === "number" && +data.item.pay_to_finaccount !== 0 ? Math.round(+data.item.summa_val_value / data.item.pay_to_finaccount * 100) : 0) + '%'
          }}
        </div>
      </template>
      <template #cell(cost_price_unit)="data">
        <div :style="{width: data.field.columnWidth}">
          {{ (+data.item.quantity ? (data.item.cost_price / (+data.item.quantity)) : 0).toFixed().replace('.', ',') }}
        </div>
      </template>
      <template #cell(cost_price_percent)="data">
        <div :style="{width: data.field.columnWidth}">
          {{
            (typeof +data.item.summa === "number" && +data.item.summa !== 0 && data.item.cost_price !== 0 ? Math.round(+data.item.cost_price / data.item.summa * 100) : 0) + '%'
          }}
        </div>
      </template>
    </b-table>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormDatepicker,
  BFormSelectOption,
  BTable,
  BCard,
  BAvatar,
  BFormCheckbox,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormDatepicker,
    BFormSelectOption,
    BTable,
    BCard,
    BAvatar,
    BFormCheckbox,
    BFormSelect,
  },
  name: "TableUnitDetailsSize",
  data() {
    return {}
  },
  props: {
    nmID: Number
  },
  methods: {
    getPromoUnit(data) {
      if (data.item.quantity > 0) {
        return ((+data.item.promo_summa + +data.item.promo_summa_balance) / data.item.quantity).toFixed(2).replace('.', ',');
      }
      return 0
    },
    ...mapActions('unitReport', ['addSubSortDetail', 'addSubSortToOperation']),
    ...mapActions('unitReportScheme', ['updateUnitFields']),
    async setSubSort({nmID, barcode, value}) {
      await this.addSubSortDetail({nmID, barcode, value})
    },
  },
  computed: {
    ...mapGetters('unitReportScheme', ["getFieldsByGroup"]),
    ...mapState('unitReport', ['unitReportDetails', 'subSortItems', "unitReportSum", "periodLength"]),
    ...mapState('unitReportScheme', ['listFields']),
    getDetailsRows() {
      return this.unitReportDetails.find(item => item[0]?.nmID === this.nmID)
    },
  },

  mounted() {
    // this.updateUnitFields()
  }
}
</script>

<style scoped>

.forecast-container {
  width: 150px;
}

.table-detail-sizes td {
  padding: 0;
  margin: 0;
}

.hidden-header {
  opacity: 0;
}

.label-size {
  right: 110%;
  top: 7px;
  font-style: italic;
}


.table-support-sizes {
  position: relative;
  top: -45px;
}


</style>

